import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import PatchNoteCard from "../components/Cards/PatchNoteCard";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";

// ====

const PatchNotes = ({ blok, data, pageContext }) => {
    let doc;
    let notes;
    if (pageContext && data) {
        //    doc = JSON.parse(data.index.content);
        doc = JSON.parse(pageContext.story.content);
        notes = data.notes;
    } else {
        doc = blok;
    }

    const openGraph = {
        type: "website",
        title: doc.title,
        description: "Hutch Game Updates",
        image:
            (doc.hero?.filename && doc.hero) ||
            (doc.logo?.filename && doc.logo),
    };

    return (
        <Layout
            title="GAME UPDATES"
            colour="red"
            image={doc.hero && doc.hero}
            logo={doc.logo && doc.logo}
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <section className="mb-4 mb-md-5">
                <div className="container">
                    {notes &&
                        notes.nodes.map((node) => {
                            // const note = JSON.parse(node.content);
                            return (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <PatchNoteCard data={node} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </section>
        </Layout>
    );
};

export default PatchNotes;

export const QUERY = graphql`
    query PatchNotesIndexDefaultQuery(
        $parent_slug: String!
        $parent_regex: String!
    ) {
        index: storyblokEntry(
            field_component: { eq: "game" }
            lang: { eq: "default" }
            slug: { eq: $parent_slug }
        ) {
            full_slug
        }
        notes: allStoryblokEntry(
            filter: { full_slug: { regex: $parent_regex } }
            sort: { fields: [field_date_string], order: [DESC] }
        ) {
            nodes {
                full_slug
                field_date_string
                field_title_string
                content
                parent_id
            }
        }
    }
`;

PatchNotes.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
    data: PropTypes.object,
};

PatchNotes.defaultProps = {
    pageContext: null,
    blok: null,
    data: null,
};
