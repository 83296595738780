import Background from "../Global/Background";
import Link from "../Global/Link";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const PatchNoteCard = (props) => {
    const { data, layout } = props;

    const note = JSON.parse(data.content);

    return (
        <div
            className={`w-100 brand-background-white brand-text-black position-relative d-flex flex-row align-items-center ${
                layout === "small" ? "mb-4" : ""
            }`}
        >
            <div className="d-flex flex-column flex-md-row w-100">
                {layout !== "small" && (
                    <Background
                        src={note.image?.filename}
                        sm="200x0"
                        md="400x0"
                        lg="400x0"
                        className="w-25 d-none d-md-block"
                    />
                )}
                <div
                    className={`d-flex flex-column p-4 ${
                        layout === "small" ? "w-100" : "w-100"
                    }`}
                >
                    <div className="d-flex flex-row justify-content-between align-items-top w-100">
                        {note.title && (
                            <h6 className="brand-font-uppercase brand-font-bold m-0">
                                {note.title}
                            </h6>
                        )}
                        {note.version && (
                            <span className="badge text-decoration-none brand-badge brand-background-red mb-auto ms-2">
                                {note.version}
                            </span>
                        )}
                    </div>
                    <div>
                        <p className="brand-font-uppercase brand-font-bold brand-text-grey small">
                            <Moment format="Do MMMM YYYY" date={note.date} />
                        </p>
                    </div>
                    {layout !== "small" && (
                        <div>
                            <SmartText>{note.preview}</SmartText>
                        </div>
                    )}
                    <Link to={data.full_slug} stretched />
                </div>
            </div>
        </div>
    );
};

export default PatchNoteCard;

PatchNoteCard.propTypes = {
    data: PropTypes.object,
};

PatchNoteCard.defaultProps = {
    data: null,
};
